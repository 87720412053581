@import '~antd/lib/style/themes/default.less';

.login-wrapper {
    background: #ffffff;
    border-radius: 2px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: relative;
    min-width: 250px;
    max-width: 350px;
}

.login-layout {
    position: relative;
    width: 100%;
    min-width: 300px;
    height: 100vh;
    background: #f5f5f5;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
