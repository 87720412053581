.legend {
    display: flex;
    align-items: center;
}

.circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin: 0 .5rem;
    margin-left: .75rem;
  }

.ant-spin-dot-item {
    background-color: darkslategray;
}

.handle-stripes {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 25%, rgba(131, 127, 127, 0.45) 25%, rgba(131, 127, 127, 0.45) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, rgba(131, 127, 127, 0.45) 75%, rgba(131, 127, 127, 0.45) 100%);
    background-size: 8.00px 8.00px;
}
.hover-columns:hover {
    background-color: #f0f0f0;
}

.freight-stripes {
    background-image: linear-gradient(55deg, rgba(255,255,255,0) 25%, #5F5370 25%, #5F5370 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 75%, #5F5370 75%, #5F5370 100%);
    background-size: 24.42px 34.87px;
}

.pending-stripes {
    background-image: linear-gradient(90deg, #757575 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0)  50%, #757575 50%, #757575 75%, rgba(0,0,0,0)  75%, rgba(0,0,0,0)  100%);
    background-size: 32.00px 32.00px;
}

.routes-schedule {
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2)
    }
} 