.logo {
  height: 38px;
  margin: 16px;
}

.main-header {
    text-align: right;
    border-bottom: 1px solid #f0f0f0;
	padding-left: 24px;
	padding-right: 24px;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    width: calc(100% - 200px);
    border-bottom: 1px solid #f0f0f0;
}

main > section {
    height: 100%;
}

.user-panel {
    float: right;
    width: 200px;
    background-color: #fff;
}

.app-logo {
    height: 50px;
    border-radius: 96px;
}

.home-icon {
    background: inherit !important;
    cursor: default;
}

.ant-layout-header {
    height: 64px;
    padding: 0 0px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
}

.site-layout-sidebar {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 1000px;
    background-color: #fff;
    position: fixed;
    z-index: 11;
    height: 100%;
}

.mobile-header {
    padding-top: 0.8rem;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 64;
    border-bottom: 1px solid #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.fixed-bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    border-top: 1px solid #ddd;
    width: 100vw;
    display: flex;
    justify-content: center;
    height: 56;
  }
  